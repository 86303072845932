import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";
import config from "../../../../config";
import {Gallery} from "../../Galeries/hooks/useGalleries";
import useAuth from "../../../../utils/auth";
import {notifications} from "@mantine/notifications";
import {randomIntFromInterval} from "../../../../utils/random";


export interface Poster {
  id: number|null
  name: string
  nameSize: string,
  nameColor: string,
  text: string
  bg: string
  images: string
}

export const defaultPoster: Poster = {
  id: null,
  "name": "<p>Za super akcí!</p>",
  "nameSize": "lg",
  "nameColor": "#007BC2",
  "text": "<p>Pojedeme na akci! Etiam commodo dui eget wisi. Mauris metus. Aliquam ornare wisi eu metus. Mauris suscipit, ligula sit amet pharetra semper, nibh ante cursus purus, vel sagittis velit mauris vel metus. Nullam eget nisl. Nullam sit amet magna in magna gravida vehicula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Aenean placerat. Etiam quis quam. Pellentesque arcu. Curabitur sagittis hendrerit ante. Nulla est.</p><p></p><p><strong>Pro koho:</strong> nejlepší skupina</p><p><strong>Kdy: </strong>xx. ledna xxxx</p><p><strong>Sraz:</strong></p><p><strong>Rozchod/Návrat:</strong></p><p><strong>Cena:</strong></p><p><strong>S sebou:</strong></p><p></p><p style=\"text-align: center\"><em>Přihlašování do xx. xxxx na </em><a target=\"_blank\" rel=\"noopener noreferrer nofollow\" href=\"http://www.skautstezery.cz\"><em>www.skautstezery.cz</em></a></p><p style=\"text-align: center\"></p><p><strong>Kontakt:</strong></p>",
  "bg": randomIntFromInterval(0, 8).toString(),
  "images": "17"
}

const usePosters = () => {
  const auth = useAuth()

  const posters = useQuery({
    queryKey: ['posters'],
    queryFn: () =>
      axios
        .get<Poster[]>(`${config.apiURL}/api/app/posters`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })

  return {
    ...posters
  }
}

export const usePoster = (id: string) => {
  const auth = useAuth()

  const poster = useQuery({
    queryKey: ['poster', id],
    queryFn: () =>
      axios
        .get<Poster>(`${config.apiURL}/api/app/posters/${id}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data),
  })


  return {
    ...poster
  }
}

export const useSavePoster = () => {
  const auth = useAuth()

  return (poster: Poster) => {
    if (!poster.id) {
      return axios.post(`${config.apiURL}/api/app/posters`, poster, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data)
    } else {
      return axios.put(`${config.apiURL}/api/app/posters/${poster.id}`, poster, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data)
    }
  }
}

export const useUpdatePoster = () => {
  const auth = useAuth()

  return useMutation({
    mutationKey: ["poster-update"],
    mutationFn: (arg: {poster: Poster, after?: () => void}) =>{
      return axios.put(`${config.apiURL}/api/app/posters/${arg.poster.id}`, arg.poster, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
        .then((res) => res.data)
        .then(() => {
          if(arg.after) arg.after()

          notifications.show({
            title: 'Uloženo',
            message: '',
          })
        })
    }
  })
}

export const useDeleteSavePoster = () => {
  const auth = useAuth()

  return (id: number) => {
    return axios.delete(`${config.apiURL}/api/app/posters/${id}`, {headers: {'Authorization': `Bearer ${auth.apiKey}`}})
      .then((res) => res.data)
  }
}


export default usePosters
